import {extend} from 'lodash';
import {EnvironmentBaseService} from "src/environments/environment-base.service";


export const environment = extend(EnvironmentBaseService, {
  siteenv: 'uat',
  listDataS3Bucket: 'skf-list-data-uat',
  webAppFilesS3Bucket: 'skf-upload-files-web-apps-uat',
  APIEndpoint: 'https://uatappsapi.skfilluminate.net',
  APIEndpointMariaDB: 'https://q3ylau6aph.execute-api.us-east-1.amazonaws.com/Stage',
  APISnowflakeEndpoint: 'https://of2734zd00.execute-api.us-east-1.amazonaws.com/uat',
  loginurl: 'https://appsuat-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=5sj7s2m7r6qo7laivfa9425lkv&response_type=code&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://appsuat2.skfilluminate.net',

});

